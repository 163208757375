var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { disabled: _vm.loader },
                  on: { click: _vm.getMetrics },
                },
                [_c("i", { staticClass: "fa fa-refresh" })]
              ),
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "text-center",
                  attrs: { src: require("@assets/home.png") },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }