<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="12">
        <b-button class="float-right" :disabled="loader" @click="getMetrics">
          <i class="fa fa-refresh" />
        </b-button>
        <div class="text-center">
          <img class="text-center" src="@assets/home.png">
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ApexLine from '@components/graphics/apex-line';
import ApexBar from '@components/graphics/apex-bar';
import ContentLoading from '@components/content-loading';

import GET_METRICS from '@graphql/dashboard/queries/overview.gql';

export default {
  name: 'Dashboard',
  page: {
    title: 'Dashboard',
  },
  components: {
    Layout,
    ApexLine,
    ApexBar,
    ContentLoading
  },
  data () {
    return {
      metrics: {},
      loader: true
    }
  },
  mounted () {
    this.valideOneHour();
  },
  methods: {
    changeAddress ($event) {
      console.log('novo endereco -> ', $event)
    },
    async getMetrics () {
      this.loader = false;
      // this.loader = true;
      // try {
      //   const response = await this.$apollo.query({
      //     query: GET_METRICS,
      //     fetchPolicy: 'network-only'
      //   });
      //   this.metrics = response.data;
      //   this.saveInStorage();
      //   this.loader = false;
      // } catch (err) {
      //   console.error(err);
      // }
    },
    saveInStorage () {
      const dateTime = this.$moment().utc();
      const dataStorage = {
        ...this.metrics,
        dateTime
      }
      localStorage.setItem('dashboard', JSON.stringify(dataStorage));
    },
    loadInStorage () {
      const dashboard = JSON.parse(localStorage.getItem('dashboard'));
      if (dashboard) {
        this.metrics = dashboard;
      }
    },
    valideOneHour () {
      const dashboard = JSON.parse(localStorage.getItem('dashboard'));
      if (dashboard) {
        const dateTime = this.$moment(dashboard.dateTime);

        const differenceMinutes = this.$moment().utc().diff(dateTime, 'minutes');

        if (differenceMinutes > 59) {
          this.getMetrics()
        } else {
          this.loadInStorage()
          this.loader = false;
        }
      } else {
        this.getMetrics()
      }
    }
  }
};
</script>
